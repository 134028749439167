import React from 'react';
import AppNavbar from './AppNavbar';
import Breadcrumbs from './Breadcrumbs';

const Layout = ({ children }) => {
    return (
        <div className="min-vh-100 d-flex flex-column">
            <Breadcrumbs />
            <main className="flex-grow-1">
                {children}
            </main>
        </div>
    );
};

export default Layout; 