import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import AppNavbar from './components/AppNavbar';
// import Navbar from './components/Navbar'; 
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import Landing from './components/Landing';
import Trending from './components/watch/Trending';
import CdramasPage from './components/watch/CdramasPage';
import CmoviesPage from './components/watch/CmoviesPage';
import ManhuasPage from './components/watch/ManhuasPage';
import DonghuasPage from './components/watch/DonghuasPage';
// import CvarietiesPage from './pages/watch/CvarietiesPage';
import TitlePage from './components/watch/TitlePage';
import SearchTitleResults from './components/watch/SearchTitleResults';
import NewTitles from './components/watch/NewTitles';
import UserPage from './components/user/UserPage';
import UserAccountForm from './components/user/UserAccountForm';
import UserGeneratedImages from './components/user/UserGeneratedImages';
import UserTitleReviews from './components/user/UserTitleReviews';
import UserLikes from './components/user/UserLikes';
import UserSaves from './components/user/UserSaves';
import WatchlinkPage from './components/watch/WatchlinkPage';
import CreateWrapper from './components/create/CreateWrapper'
import GenerateImage from './components/create/GenerateImage';
import Gallery from './components/create/Gallery';
import AdminGallery from './components/create/AdminGallery';
import GetTokens from './components/pay/GetTokens';
import SearchProductResults from './components/shop/SearchProductResults';
// import Market from './components/shop/Market';
import ProductsPage from './components/shop/ProductsPage';
import ProductPage from './components/shop/ProductPage';
import Pricing from './components/pay/Pricing';
import CheckoutWrapper from './components/pay/CheckoutWrapper';
import Forum from './components/discuss/Forum';
import PostForm from './components/discuss/PostForm';
import Events from './components/experience/Events';
import QRCode from './components/QRCode';
import Footer from './components/Footer';
import Terms from './components/terms/Terms';
import Privacy from './components/terms/Privacy';
import About from './components/about/About';
import Contact from './components/about/Contact';
import Report from './components/watch/Report';
import Success from './components/pay/Success';
import Cancel from './components/pay/Cancel';

import './css/App.css';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <AppNavbar />
          <Layout>
            {/* <Navbar /> */}
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/watch/trendings' element={<Trending />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/login' element={<Login />} />
              <Route path='/watch/cdramas' element={<CdramasPage />} />
              <Route path='/watch/cmovies' element={<CmoviesPage />} />
              <Route path='/watch/manhuas' element={<ManhuasPage />} />
              <Route path='/watch/donghuas' element={<DonghuasPage />} />
              {/* <Route path='/watch/cvarieties' element={<CvarietiesPage />} /> */}
              <Route path='/watch/search/titles' element={<SearchTitleResults />} />
              <Route path='/watch/titles/:titleId' element={<TitlePage />} />
              <Route path='/watch/new' element={<NewTitles />} />
              <Route path='/users/:username/account' element={<UserAccountForm />} />
              <Route path='/users/:username' element={< UserPage />} />
              <Route path='/users/:username/images' element={<UserGeneratedImages />} />
              <Route path='/users/:username/title_reviews' element={<UserTitleReviews />} />
              <Route path='/users/:username/likes' element={<UserLikes />} />
              <Route path='/users/:username/saves' element={<UserSaves />} />
              <Route path='/watch/titles/:titleId/watchlinks/:watchlinkId' element={<WatchlinkPage />} />
              <Route path='/create' element={<CreateWrapper />} />
              <Route path='/create/users/:userId' element={<GenerateImage />} />
              <Route path='/create/gallery' element={<Gallery />} />
              <Route path='/admin/create/gallery' element={<AdminGallery />} />
              <Route path='/pay/tokens' element={<GetTokens />} />
              <Route path='/pricing' element={<Pricing />} />
              {/* <Route path='/shop' element={<Market />} /> */}
              <Route path='/checkout' element={<CheckoutWrapper />} />
              <Route path="/success?session_id={CHECKOUT_SESSION_ID}" element={<Success />} />
              <Route path="/cancelled" element={<Cancel />} />
              <Route path='/shop' element={<ProductsPage />} />
              <Route path='/shop/products/:productId' element={<ProductPage />} />
              <Route path='/shop/search/products' element={<SearchProductResults />} />
              <Route path='/forum' element={<Forum />} />
              <Route path='/forum/create' element={<PostForm />} />
              <Route path='/events' element={<Events />} />
              <Route path='/qrcode' element={<QRCode />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/about" element={<Landing />} />
              <Route path='/contact' element={<Contact />} />
              <Route path="/report" element={<Report />} />
            </Routes>
          </Layout>
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
