// src/pages/Trending.js

import React, { useState, useEffect } from 'react'; 
import TrendingList from './TrendingList'; 
import '../../css/Page.css';

function Trending () {

    const bannerLink = "/watch/new";
    const bannerMessage = "最新添加325个片名！包括多部童年经典漫画! 325 newly add titles! Includes many classic childhood Manhuas! Check them out! 🎉🥳";

    const [trendingLists, setTrendingLists] = useState({
        trending: [], 
        cdrama: [], 
        cmovie: [], 
        donghua: [], 
        manhua: [],
        // cvariety: []
    }); 

    useEffect(() => {
        // This function fetches titles by type and updates the state accordingly.
        const fetchTitlesByType = async (type) => {
            console.log(type);
            const TRENDING_API_URL = process.env.REACT_APP_API_BASE_URL + `/api/watch/titles?type=${type}&sortBy=views` || `http://localhost:3000/api/watch/titles?type=${type}&sortBy=views`;
            console.log(TRENDING_API_URL)
            try {
                const response = await fetch(TRENDING_API_URL); 
                if (!response.ok) {
                    throw new Error('Network response was not ok'); 
                }
                const data = await response.json(); 
                setTrendingLists(prevTrendingLists => ({ ...prevTrendingLists, [type]: data })); 
            } catch (error) {
                console.error(`Error fetching ${type} titles:`, error);
            }
        };

        // Fetch titles for each trending list 
        fetchTitlesByType('trending'); 
        fetchTitlesByType('cdrama'); 
        fetchTitlesByType('cmovie'); 
        fetchTitlesByType('donghua'); 
        fetchTitlesByType('manhua');
        // fetchTitlesByType('cvariety'); 
    }, []); 

    return (
        <div className="page-wrapper">
            <div className="page-banner">
                <a href={bannerLink} target="_blank" rel="noopener noreferrer" className="banner-text">{bannerMessage}</a>
            </div>
            <TrendingList title="所有热播片 All Trending Titles" tag="热播 Trending" titles={trendingLists.trending} titlesType="trending" />
            <TrendingList title="热播连续剧 Trending C-Dramas" tag="连续剧 C-Dramas" titles={trendingLists.cdrama} titlesType="cdrama" />
            <TrendingList title="热播电影 Trending C-Movies" tag="电影 C-Movies"  titles={trendingLists.cmovie} titlesType="cmovie" />
            <TrendingList title="热播动画 Trending Donghuas" tag="动画 Donghuas" titles={trendingLists.donghua} titlesType="donghua" />
            <TrendingList title="热播漫画 Trending Manhuas" tag="漫画 Manhuas" titles={trendingLists.manhua} titlesType="manhua" /> 
            {/* <TrendingList title="热播综艺 Trending C-Varieties" tag="综艺 C-Varieties" titles={trendingLists.manhua} titlesType="manhua" /> */}
        </div>
    );
};

export default Trending; 