//src/components/LandingCarousel.js

import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { FaChevronLeft, FaChevronRight, FaPause, FaPlay } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../css/LandingCarousel.css";

const aiImage1 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_0_20240502114249.png";
const aiImage2 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_1_1718602990268.png";
const aiImage3 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506132925.png";
const aiImage4 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_1_1718604635191.png";
const aiImage5 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_1_20240617042611.png";
const aiImage6 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_1_20240607234513.png";
const aiImage7 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_13_20240613043325.png";
const aiImage8 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/test_generated_image_0_20240501180927.png";
const aiImage9 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/test_generated_image_0_20240501164252.png";
// const aiImage10 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506141345.png";
// const aiImage11 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506135947.png";
// const aiImage12 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506132925.png";
// const aiImage13 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506131752.png";
// const aiImage14 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506131615.png";
// const aiImage15 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240506112412.png";
// const aiImage16 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240502154017.png";
// const aiImage17 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240502123934.png";
// const aiImage18 = "https://hanfuworld-ai-images.s3.us-east-2.amazonaws.com/user_generated_image_20240502121228.png";


const productImage1 = "https://m.media-amazon.com/images/I/61qw5fktC9L._AC_SX679_.jpg";
const productImage2 = "https://m.media-amazon.com/images/I/71+njwrDNZL._AC_SX679_.jpg";
const productImage3 = "https://m.media-amazon.com/images/I/71PG-OEZSnL._AC_SX679_.jpg";
const productImage4 = "https://m.media-amazon.com/images/I/51O0rzcAFNL._AC_SX679_.jpg";
const productImage5 = "https://m.media-amazon.com/images/I/51SRv92m9jL._AC_SX679_.jpg";
const productImage6 = "https://m.media-amazon.com/images/I/61uqiq9HoeL._AC_SX679_.jpg";

const PreviousArrow = ({ className, style, onClick }) => (
    <div
        className={`${className} custom-arrow custom-arrow-left`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
    >
        <FaChevronLeft className="carousel-arrow-icon" />
    </div>
);

const NextArrow = ({ className, style, onClick }) => (
    <div
        className={`${className} custom-arrow custom-arrow-right`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
    >
        <FaChevronRight className="carousel-arrow-icon" />
    </div>
);

const LandingCarousel = () => {
    const [isPlaying, setIsPlaying] = useState(true);
    const mainSliderRef = useRef(null);
    const innerSliderRefs = useRef({});

    useEffect(() => {
        if (isPlaying) {
            playAllSliders();
        } else {
            pauseAllSliders();
        }
    }, [isPlaying]);

    const playAllSliders = () => {
        if (mainSliderRef.current) {
            mainSliderRef.current.slickPlay();
        }

        Object.values(innerSliderRefs.current).forEach(slider => {
            if (slider && slider.slickPlay) {
                slider.slickPlay();
            }
        });
    };

    const pauseAllSliders = () => {
        if (mainSliderRef.current) {
            mainSliderRef.current.slickPause();
        }

        Object.values(innerSliderRefs.current).forEach(slider => {
            if (slider && slider.slickPause) {
                slider.slickPause();
            }
        });
    };

    const togglePlayPause = () => {
        setIsPlaying(prevState => !prevState);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: isPlaying,
        autoplaySpeed: 3000,
        prevArrow: <PreviousArrow />,
        nextArrow: <NextArrow />,
        cssEase: 'ease-in-out', // Use a smooth easing function
        beforeChange: (_, nextSlide) => {
            const currentInnerSlider = innerSliderRefs.current[nextSlide];
            if (currentInnerSlider && isPlaying) {
                // Pause main carousel until inner carousel has completed its cycle
                mainSliderRef.current.slickPause();
                if (currentInnerSlider.slickPlay) {
                    currentInnerSlider.slickPlay();
                }
            }
        },
        afterChange: (currentSlide) => {
            const currentInnerSlider = innerSliderRefs.current[currentSlide];
            if (currentInnerSlider && isPlaying) {
                // Ensure the inner carousel continues to autoplay
                if (currentInnerSlider.slickPlay) {
                    currentInnerSlider.slickPlay();
                }
            }
        }
    };

    const innerSettings = {
        infinite: true,
        speed: 3000,
        slidesToShow: 3, // Show multiple images in a row
        slidesToScroll: 1,
        autoplay: isPlaying,
        autoplaySpeed: 0, // Set to 0 for a continuous scroll
        // autoplay: false, 
        cssEase: 'linear', // Smooth linear transition
        arrows: false, // Disable arrows on the inner slider
        draggable: false, // Disable dragging for a smoother auto-scroll
        swipeToSlide: true, // Enables swiping directly to the next slide
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],

        afterChange: (currentSlide) => {
            if (innerSliderRefs.current.aiImages && innerSliderRefs.current.aiImages.innerSlider) {
                const totalSlides = innerSliderRefs.current.aiImages.innerSlider.state.slideCount;
                if (currentSlide >= totalSlides - 3 && isPlaying) {
                    // Allow main carousel to move to next slide after inner carousel completes its cycle
                    if (mainSliderRef.current && mainSliderRef.current.slickPlay) {
                        mainSliderRef.current.slickPlay();
                    }
                }
            }
        }
    };

    return (
        <div className="carousel-container">
            <Slider {...settings} ref={mainSliderRef}>
                {/* Slide 1: YouTube Trailer Embed */}
                <div className="slide trailer-slide">
                    <a href="/watch/trending" className="section-link" target="_blank">
                        <h3 className="slide-title">▶️ 观看 Watch</h3>
                    </a>
                    <div className="video-container">
                        <iframe
                            className="trailer-embed-video"
                            // src="https://www.youtube.com/embed/IjZ5K0b8BFo?rel=0"
                            src="https://www.youtube.com/embed/17hpPXh3Zeg?rel=0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>

                {/* Slide 2: AI Images Carousel */}
                <div className="slide ai-images-slide">
                    <a href="/create" className="section-link" target="_blank">
                        <h3 className="slide-title">❤️ 创作 Create</h3>
                    </a>
                    <Slider
                        {...innerSettings}
                        // key={autoplay ? "play" : "pause"} 
                        ref={slider => (innerSliderRefs.current['aiImages'] = slider)}
                    >
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage1} className="ai-image" alt="AI Image 1" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage2} className="ai-image" alt="AI Image 2" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage3} className="ai-image" alt="AI Image 3" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage4} className="ai-image" alt="AI Image 4" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage5} className="ai-image" alt="AI Image 5" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage6} className="ai-image" alt="AI Image 6" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage7} className="ai-image" alt="AI Image 7" /></a>
                        <a href="/create" className="section-link ai-image-slide" target="_blank"><img src={aiImage8} className="ai-image" alt="AI Image 8" /></a>
                        {/* <a href="/create" className="section-link" target="_blank"><img src={aiImage9} className="ai-image" alt="AI Image 9" /></a> */}
                        {/* <a href="/create" className="section-link" target="_blank"><img src={aiImage10} className="ai-image" alt="AI Image 10" /></a> */}
                        {/* <a href="/create" className="section-link" target="_blank"><img src={aiImage11} className="ai-image" alt="AI Image 11" /></a> */}
                        {/* <a href="/create" className="section-link" target="_blank"><img src={aiImage12} className="ai-image" alt="AI Image 12" /></a> */}
                        {/* <a href="/create" className="section-link" target="_blank"><img src={aiImage13} className="ai-image" alt="AI Image 13" /></a> */}


                    </Slider>
                </div>

                {/* Slide 3: Product Cards Carousel */}
                <div className="slide products-slide">
                    <a href="/shop" className="section-link" target="_blank">
                        <h3 className="slide-title">🛍️ 购物 Shop</h3>
                    </a>
                    <Slider
                        {...innerSettings}
                        // key={autoplay ? "play" : "pause"} 
                        ref={slider => (innerSliderRefs.current['products'] = slider)}
                    >
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage1} alt="Product 1" />
                            <span>唐制汉服齐胸儒裙套装 - 红</span>
                            <span>Tang Dyansty Qixiong Ruqun Tube Hanfu Dress Set - Red</span>
                        </a>
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage2} alt="Product 2" />
                            <span>唐制汉服宽袖齐腰儒裙套装 - 白</span>
                            <span>Tang Dyansty Wide Sleeve Qiyao Ruqun Hanfu Dress Set - White</span>
                        </a>
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage3} alt="Product 3" />
                            <span>唐制汉服宽袖齐腰儒裙套装 - 蓝</span>
                            <span>Tang Dyansty Wide Sleeve Qiyao Ruqun Hanfu Dress Set - Blue</span>
                        </a>
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage4} alt="Product 3" />
                            <span>魏晋制百褶裙汉服套装</span>
                            <span>Wei/Jin Dynasty Pleated Skirt Hanfu Dress Set</span>
                        </a>
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage5} alt="Product 3" />
                            <span>唐制对襟齐腰儒裙汉服套装</span>
                            <span>Tang Dynasty Duijin Top & Qiyao Ruqun Dress Hanfu Set</span>
                        </a>
                        <a href="/shop" className="product-card-slide section-link" target="_blank">
                            <img src={productImage6} alt="Product 3" />
                            <span>唐制汉服披帛齐胸儒裙套装</span>
                            <span>Tang Dyansty Pibo(Drape) Qixiong Ruqun Tube Hanfu Dress Set</span>
                        </a>
                    </Slider>
                </div>

                {/* Slide 4: Forum Image */}
                {/* <div className="slide forum-slide">
                    <h3 className="slide-title">💬 交流 Discuss</h3>
                    <img src="forum-image.jpg" alt="Forum" />
                </div> */}

                {/* Slide 5: Event Cards Carousel */}
                {/* <div className="slide events-slide">
                    <h3 className="slide-title">🫶🏻 体验 Experience</h3>
                    <Slider {...innerSettings}>
                        <div className="event-card">
                            <img src="event-image-1.jpg" alt="Event 1" />
                            <span>Event 1</span>
                        </div>
                        <div className="event-card">
                            <img src="event-image-2.jpg" alt="Event 2" />
                            <span>Event 2</span>
                        </div>
                        <div className="event-card">
                            <img src="event-image-3.jpg" alt="Event 3" />
                            <span>Event 3</span>
                        </div>
                    </Slider>
                </div> */}
            </Slider>
            <button className="btn btn-light btn-carousel-pause" onClick={togglePlayPause}>
                {isPlaying ? <FaPause /> : <FaPlay />}
            </button>
        </div>
    );
};

export default LandingCarousel;
